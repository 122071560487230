<script lang="ts">
  import LoadingSpinner from "./LoadingSpinner.svelte";

  let email = "";
  let hasError = false;
  let submitting = false;

  async function checkEmail() {
    const response = await fetch(
      `https://${
        _WINE_CLUB_API_CONFIG.api.domain
      }/api/authprovider/users/${encodeURIComponent(email)}/exists?code=${
        _WINE_CLUB_API_CONFIG.api.code
      }`,
      {
        method: "GET",
        headers: new Headers([
          ["Content-Type", "application/json"],
          ["Accept-Type", "application/json"],
        ]),
      },
    );

    if (response.status !== 200) {
      throw new Error(await response.text());
    }

    return (await response.json()) as { exists: boolean };
  }

  async function handleSubmit(event: SubmitEvent) {
    event.preventDefault();
    submitting = true;
    hasError = false;

    try {
      const loginUrl =
        _WINE_CLUB_API_CONFIG.login_url ??
        window.location.origin + "/wp-login.php";
      const { exists } = await checkEmail();
      if (exists) {
        window.location.replace(`${loginUrl}?email=${email}`);
      } else {
        window.location.replace(`${loginUrl}?action=register&email=${email}`);
      }
    } catch (error) {
      console.log(error);
      hasError = true;
    } finally {
      submitting = false;
    }
  }
</script>

<form id="wine-club-login-form" on:submit={handleSubmit}>
  <!-- svelte-ignore missing-declaration -->
  <h2>{_WINE_CLUB_API_CONFIG.pre_login_screen.heading}</h2>
  <label for="wine-club-email">Please enter your email address.</label>
  <div id="wine-club-login-input-wrapper">
    <input
      id="wine-club-login-email"
      type="email"
      required
      readonly={submitting}
      bind:value={email}
    />
    <button type="submit">Submit</button>
    {#if submitting}
      <LoadingSpinner />
    {/if}
  </div>
  <span id="wine-club-login-error" class:show={hasError}
    >Something has gone wrong!</span
  >
</form>
