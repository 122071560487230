<script lang="ts">
  import Form from "./Form.svelte";
  import LoadingSpinner from "./LoadingSpinner.svelte";

  export let onClose: () => void = () => {};

  const { background_image, logo } = _WINE_CLUB_API_CONFIG.pre_login_screen;

  let loaded = false;
  let hidden = background_image && !loaded;

  function handleBackgroundLoad() {
    loaded = true;
    if (import.meta.env.DEV) {
      setTimeout(() => {
        hidden = false;
      }, 1000);
    } else {
      hidden = false;
    }
  }
</script>

<div id="wine-club-login">
  <div id="wine-club-login-backdrop" />
  <div id="wine-club-login-container">
    {#if background_image}
      <img
        alt="Background"
        id="wine-club-login-background"
        src={background_image}
        on:load={handleBackgroundLoad}
        class:show={!hidden}
      />
    {/if}
    {#if logo && !hidden}
      <img alt="Logo" id="wine-club-login-logo" src={logo} />
    {/if}
    {#if hidden}
      <LoadingSpinner />
    {/if}
    {#if !hidden}
      <Form />
      <button id="wine-club-login-close-button" on:click={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    {/if}
  </div>
</div>
